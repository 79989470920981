@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,700&display=swap');

:root{
  --dark: #051A36;
  --primary: #07305E;
  --secondary: #0F3866;
  --white: #FFF; 
}
#root{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
body{
  transition: 01s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

/* BODY */
.App {
  text-align: center;
  font-family:"Montserrat", sans-serif;
  transition: 01s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;

}

.light{
  color: var(--dark);
}
.dark{
  color: white;
  background:var(--dark);
}

.main{
  display: flex;
  flex-direction: column;
}




#myname{
  padding: 3px 15px;
  font-size: 0.7rem;
  font-family: "lato";
}


/* HERO ==================================*/
.hero{
  font-family: "lato";
}
.hero p{
  font-size: 1.4vh;

}
.slogan{
  padding: 1.5rem 0rem;
}
.hero hr{
  margin:auto;
  width: 45vw;
}

.hero-intro{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:70%;
}
/*Book===========================*/

.book--content{
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4vh;
}
.book-img{
  width:30%;
  border: 5px ridge white;
  height: auto;
}
.book--content a{
  text-decoration: none;
  font-size: 0.65rem;
  cursor: pointer;
  margin: 1rem 0rem 1rem 0rem;
  background-color: var(--dark);
  padding: 10px;
  color: white;
  font-weight: bold;
}

.book--content a:hover{
  background-color: rgb(40,40,40);
}
/*PUBLICATION */

.publication{
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  background-color: white;
}
.translation{
  padding: 3vh 8vw 3vh 8vw;
  text-align: justify;
  width: 100%;

}
#racism{
  margin: 0vh 0vw 4vh 0vw;
  font-size: 2rem;
  text-align: center;
}

.publication p{
  font-size: 0.8rem;
}
.publication img{

  width: 100%;
  margin: auto;
}
.notice{
  margin-top: 120px;
  animation: float 2.5s ease-in-out infinite;
}

@keyframes float{
  0%{   
    transform: translate(0%,10%);
    font-weight: 400;
  }
  50%{
    transform: translate(0%,0%);
    opacity: 0;
    font-weight: 300;
  }
  
  100%{
    transform: translate(0%,10%);
    font-weight: 400;
  }
}






/*===========================PAGE NOT FOUND */ 

p{
  font-size: 12px;
}

/* STUDIO 243 */
.studio243{
  max-width: 50%;

  
}
.content{
  width:100%;
  margin-top: 40px;
  transition: 01s ease-in-out;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 50px;
  font-size: 20px;
  background: rgb(40,40,40);
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  height: 40px;
  width: 40px;
  z-index: 2;
  /*box-shadow: 0 5px 10px #ccc;*/
}

.back-to-top:hover {
  background: #1781C2;
}


@media (max-width: 450px){
  .studio243{
    max-width: 100%;
  }
  .switch-cont{
  left:0%;
  left:30%;
  top:unset;
  bottom: 30%;

  }
  .project-container{
    width:300px;
    grid-template-columns: 1fr;
    
  }
  .project-element{
    width: 100%;
  }
  #root{
    width:90%;
  }
}
@media (max-width: 550px){
  .studio243{
    max-width: 100%;
  }
  .switch-cont{
  left:0%;
  left:30%;
  top:unset;
  bottom: 30%;

  }
  .project-container{
    width:300px;
    
  }
  .project-element{
    width: 100%
  }
  .project-element-child{
    height:max-content;
  }
}



/*=============*/
.resume_container{
  color: black;
  position:relative;
  margin-top: 40px;
  border: 2px solid rgb(240,240,240);
  width:840px;
  display:flex;
  flex-direction: column;
  padding: 20px 30px;
}

.contact-info{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-info p{
  padding: 0;
  margin: 0;
}


.contact-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.link-status{
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}
.link-status a{
  font-size: 12px;
  color:#0F3866;
  text-decoration: none;
}

.link-status a:hover{
  font-weight: 800;
}
.link-status p {
  margin:0px
}


.section-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}
.section1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.sectionx p{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}
.positionStyle{
  font-weight: 500;
}

.section1 span{
  font-size: 10px;
  font-weight: 400;
}
.section2{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
}
.cert p{
  margin:3.5px;
}
.cert strong{
  font-weight: 600;
}
.section2 p{
  font-size: 10px;
}
.section p{
  padding:0;
  margin: 0;
}
.section{
  margin-top: 10px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 100%;
}

.section-bullet{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  text-align:justify;
  margin-bottom:0;
  width:90%
}
.section-bullet p{

  margin:2px;
  margin-right:0px;
}
.section-title{
  font-size: 15px;
  padding:5px 10px;
  width: 100%;
  background-color: rgb(240,240,240);
  text-align: left;
}

.unit{
  width:100%;
  margin-bottom: 0;
}

.gauche{
  padding-right: 10px;
}

.okay{
  color:black;
  font-size: 0.5rem;
}

.url-repo{
  color:black;
  margin-top: 0px;
}
.url-repo a{
  color: black;
}

.url-to-the-project a{
  color: black;
  margin-bottom: 5px;
}

