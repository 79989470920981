/*NAV BAR=============================================*/

nav{
    position: sticky;
    background-color: white;
    width:100%;
    height: fit-content;
    padding:0.5rem 0rem;
    top:0%;
    z-index: 1;
    margin-bottom:10px;
  }
  
  .switch-cont{
    border: 0.01rem solid var(--dark);
    position:fixed;
    left:20%;
    top: 2px;
    margin-left: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    transform: scale(0.8);
    background: white;
  }
  .switch-cont p{
    margin:0px;
  }
  .switch-container{
    border:1px solid #051A36;
    border-radius: 30px;
    height: 20px;
    width: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .switch-container:hover{
  cursor: pointer;
  }
  .switch{
    height: 24px;
    width:24px;
    background: white;
    border-radius: 30px;
    background: var(--secondary);
  
  }
  
  nav ul{
    margin: auto;
    width: 360px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
  }


.navElement .anchor{
    text-decoration: none;
    color: var(--dark);
    padding: 5px;
    font-size: 0.8rem;
    transition: 0.25s;
    margin: 5px;
  }
  
  .navElement .anchor:hover{
    opacity: 1;
    color: white;
    background-color: var(--dark);
    transition: 0.2s;
  
  }

  /*Footer ========*/

footer{
  position: fixed;
  bottom: 0px;

}
.footer{
    width:100%;
    padding: 2rem 0rem 2rem 0rem;
    transition: 01s ease-in-out;
  }
  
  .footer hr, nav hr{
    width: 35%;
    margin:auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: var(--dark);
  }
  .footer span{
    font-size: 14px;
    padding: 10px;
    transition: 01s ease-in-out;
  
  }

  .footer span:hover{
    color: #1781C2;
    opacity: 1;
  }
  .footer p{
    margin: 1rem 0rem 0.5rem 0rem;
    font-size: 8px;
    transition: 01s ease-in-out;
  
  }